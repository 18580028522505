import React from "react";
import {useMediaQuery} from "../../util/media-query";

const WariComponents = ({}:{}) => {
    const isMobile = useMediaQuery('(max-width: 850px)');
    return <>
        <div className="content-space-t-2 content-space-t-lg-2">
        <div
            className="d-flex bg-img-center"
            style={{ background: `linear-gradient(rgba(0, 0, 0, 0.3) 10%, rgba(239,136,69,0.1) 99%), linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(239,136,69,0.1) 90%), url(/assets/img/wari-hiro.svg) no-repeat ${isMobile?'center/100%':'center/65%'}` }}
        >
            <div style={{minHeight:isMobile?"50vh":"65vh"}} className="container d-flex justify-content-end flex-column content-space-1 content-space-lg-b-3">
                <div className="w-lg-60 text-center" >
                    <h1 className={isMobile?"display-6":"display-3"}><span className="text-warning">CASHBACK</span> <sub className="text-white"> By Afrisends</sub></h1>
                </div>
            </div>
        </div>

            <div className="overflow-hidden">
                <div className="container content-space-1 content-space-lg-3">

                    <div className="row justify-content-lg-between align-items-md-center">
                        <div className="col-md-12">
                            <div className="mb-5">
                                <h2 className={isMobile?"display-6":"display-3"}>Description de l'offre</h2>
                                <p style={!isMobile?{fontSize:'2em'}:undefined} >Avec l'offre <strong className="text-primary text-md-start">CASHBACK<sub> by Afrisends</sub></strong> recevez 5% de vos achats éligibles faits en France sous forme de cash back.</p>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12 mb-0 mb-md-0">
                            <div className="position-relative">
                                <img className="img-fluid rounded-3 w-100" src="/assets/img/cashback-presentation.svg"
                                     alt="Image Description" />

                                <figure className="position-absolute top-0 end-0 d-none d-lg-block mt-2 me-n7"
                                        style={{width: "4rem"}}>
                                    <img className="img-fluid" src="/assets/svg/components/pointer-up.svg"
                                         alt="Image Description" />
                                </figure>

                                <figure className="position-absolute bottom-0 start-0 mb-n8 ms-n8"
                                        style={{width: "12rem"}}>
                                    <img className="img-fluid" src="/assets/svg/components/curved-shape.svg"
                                         alt="Image Description" />
                                </figure>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="overflow-hidden">
                <div className="container content-space-1 content-space-lg-3">

                    <div className="row justify-content-lg-between align-items-md-center">
                        <div className="col-md-12">
                            <div className="mb-5">
                                <h2 className={isMobile?"display-6":"display-3"}>Comment ça marche ?</h2>
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-12 mb-10 mb-md-0">
                            <div className="position-relative">
                                <img className="img-fluid rounded-3 w-100" src="/assets/img/hitw.svg"
                                     alt="Image Description" />

                                <figure className="position-absolute top-0 end-0 d-none d-lg-block mt-3 me-n7"
                                        style={{width: "4rem"}}>
                                    <img className="img-fluid" src="/assets/svg/components/pointer-up.svg"
                                         alt="Image Description" />
                                </figure>

                                <figure className="position-absolute bottom-0 start-0 mb-n8 ms-n8"
                                        style={{width: "12rem"}}>
                                    <img className="img-fluid" src="/assets/svg/components/curved-shape.svg"
                                         alt="Image Description" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="text-end">
                        <a className="btn btn-primary btn-pointer" href="/app/profile?tab=CASHBACK">ADHEREZ AU CASHBACK</a>
                    </div>
                </div>

            </div>


        </div>
    </>
}
export default WariComponents;