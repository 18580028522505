import React from "react";
import Layout from "../components/layout/Layout";
import WariComponents from "../components/wari-components";

const Wari = () => {
  return <>
      <Layout title={"Wari"}>
          <WariComponents/>
      </Layout>
  </>
}
export default Wari;